@import "../_variables.scss";
.text{
    overflow: hidden;
    max-width: 100%;

    strong{
        font-weight: 700;
    }

    p{
        margin-bottom: 12px;
    }
    table{

        margin: 2rem 0;
        width: 100%;
        max-width: 100%;
        overflow: auto;
        display: block;

        thead{
            tr{
                background: $nav-link-color;
                color: #fff;
                border-bottom: 1px solid #ccc;

            }
            th{

                text-align: left;
                padding: 1rem 2rem;
            }
        }

        tbody{

            tr{
                border-bottom: 1px solid #ccc;
                &:nth-child(even)
                {
                    background-color: rgba($nav-link-color,.1);
                }

                td{
                    padding: 1rem 2rem;
                }
            }
        }
        .table{
            overflow-x: auto;
            max-width: 100%;
        }
    }
}
